const initialState = {
  updateParticipantResp : ""
  }
  
  const successStore = (state = initialState , action) =>{
    switch (action.type) {

          case "SET_UPDATE_PARTICIPANT_RESPONSE":
            return {
              ...state,
              participantNewStudyUrlSuccess : false,
              updateParticipantResp : action.updateParticipantResp
            }

          case 'SET_PARTICIPANT_REDIRECT_URL_RES':
            return {
              ...state,
              updateParticipantResp: false,
              participantNewStudyUrlSuccess : action.participantRedirectUrlRes.newSurveyParticipant,
              participantNewRedirectUrlData: action.participantRedirectUrlRes.data,
              
            }

        default:
            return state;
          }
  
  };
  export default successStore;
  